import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import urljoin from "url-join"
import { DiscussionEmbed } from "disqus-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/post-card/post-card"
import PostDetails from "../components/post-details/post-details"
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from "react-share"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
} from "react-icons/io"
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from "./templates.style"

interface Tag {
  name: string,
  slug: string,
}

const BlogPostTemplate = (props: any) => {
  console.log(props.data);
  const post = props.data.ghostPost
  const { edges } = props.data.allGhostPost
  const title = post.title
  const slug = post.slug
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const shareUrl = urljoin(siteUrl, slug)

  const disqusConfig = {
    shortname: "yutinefung",
    config: { url: '//yutinefung.disqus.com', identifier: slug, title },
  }
  useEffect(() => {
    if ('instgrm' in window) {
      window.instgrm.Embeds.process();
    }
    document.querySelectorAll('.load-external-scripts a').forEach((a: any) => {
      if (!a.href.match(document.domain)) {
        a.target = '_blank';
      }
    });
  });
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.meta_description || post.excerpt}
      />
      <BlogPostDetailsWrapper>
        <PostDetails
          className="load-external-scripts"
          title={post.title}
          date={post.published_at}
          preview={
            post.feature_image_sharp == null
              ? null
              : post.feature_image_sharp.childImageSharp.fluid
          }
          description={post.html}
          imagePosition="top"
        />

        <BlogPostFooter
          className={post.feature_image_sharp == null ? "center" : ""}
        >
          {post.tags == null ? null : (
            <PostTags className="post_tags">
              {post.tags.map((tag: Tag, index: number) => (
                <Link key={index} to={`/tag/${_.kebabCase(tag.slug)}/`}>
                  {`#${tag.slug}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Share This:</span>
            <FacebookShareButton url={shareUrl} quote={post.excerpt}>
              <IoLogoFacebook />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <IoLogoTwitter />
            </TwitterShareButton>
            <PinterestShareButton
              url={shareUrl}
              media={urljoin(siteUrl, post.feature_image_sharp.publicURL)}
            >
              <IoLogoPinterest />
            </PinterestShareButton>
            <RedditShareButton
              url={shareUrl}
              title={`${post.title}`}
            >
              <IoLogoReddit />
            </RedditShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment
          className={post.feature_image_sharp == null ? "center" : ""}
        >
          <DiscussionEmbed shortname='yutinefung' config={{url: `https://www.yutinefung.com/${slug}`, identifier: slug, title }} />
        </BlogPostComment>
      </BlogPostDetailsWrapper>

      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.slug}>
                <PostCard
                  title={node.title || node.slug}
                  url={`/${node.slug}`}
                  image={
                    node.feature_image_sharp == null
                      ? null
                      : node.feature_image_sharp.childImageSharp.fluid
                  }
                  tags={node.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    ghostPost(slug: {eq: $slug}) {
      id
      excerpt
      html
      slug
      title
      published_at(formatString: "DD MMM, YYYY")
      meta_description
      tags {
        name
        slug
      }
      feature_image_sharp {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1170, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    allGhostPost(
      filter: {
        slug: {ne: $slug, nin: ["data-schema"]}, 
        tags: {elemMatch: {slug: {in: $tag}}}
      },
      limit: 3,
      sort: {fields: published_at, order: DESC}
      ){
      edges {
        node {
          slug
          title
          tags {
            name
            slug
          }
          feature_image_sharp {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1170, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
